import React from "react";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import _ from "lodash";

const ChatMsgIcon = (props) => {
    const {
        msg,
        messages,
        index,
        onApproveEvent
    } = props;
    if (msg.channel === "EMAIL" && !_.toLower(msg.role).includes('contact')) {
        switch (msg?.status) {
            case "PENDING_SEND":
                return <ThumbUpOutlinedIcon
                    sx={{fontSize: 16, marginLeft: 0.5, marginTop: 1.5, color: "#b658ff"}}
                    onClick={() => onApproveEvent(msg.id, messages, index)}
                />;
            case "SCHEDULED":
                return <CalendarMonthOutlinedIcon
                    sx={{fontSize: 16, marginLeft: 0.5, marginTop: 1.5, color: "#b658ff"}}
                />;
            default:
                return <MarkEmailReadOutlinedIcon
                    sx={{fontSize: 16, marginLeft: 0.5, marginTop: 1.5, color: "#b658ff"}}
                />;
        }
    } else {
        return null;
    }

}
export default ChatMsgIcon;
